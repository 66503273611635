window.feedItems = [];

Vue.component('sisaltofeed-item', {
    template: '\
        <div class="sisaltofeed-item" v-on:click="openModal(item.nid)" v-bind:class="{ yksiSarake: item.leveys == 1, kaksiSaraketta: item.leveys == 2 }">\
            <div class="sisaltofeed-item-inner">\
                <h3 class="sisaltofeed-title"><span class="hide-text">{{ item.title }}</span><div v-bind:class="item.from"></div></h3>\
                <div class="sisaltofeed-image" v-if="item.video || item.kuvat.length > 0" v-bind:class="item.kuvat.class">\
                    <div class="sisaltofeed-flag" v-if="item.flag_link" v-on:click="flagItem(item.nid, item.flag_link, $event)" v-bind:class="{ flagged: item.flagged == 1 }"></div>\
                    <div class="sisaltofeed-image-inner" :class="item.video ? \'has-video\' : \'\'">\
                        <img v-bind:src="item.kuvat[0]" />\
                    </div>\
                    <div v-if="item.video" class="sisaltofeed-video">\
                        <a @click="play($event, item.nid)" class="video-play">\
                            <svg xmlns="http://www.w3.org/2000/svg" height="36px" viewBox="0 0 24 24" width="36px" fill="#FFFFFF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M10 16.5l6-4.5-6-4.5zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></svg>\
                        </a>\
                        <video :id="\'sfvideo-\' + item.nid" controls="controls" playsinline="playsinline">\
                            <source :src="item.video" type="video/mp4">\
                        </video>\
                    </div>\
                </div>\
                <div class="sisaltofeed-floating-text hidden" v-if="!item.kuvat_original.length">\
                    <div class="sisaltofeed-floating-text-inner" v-html="item.body"></div>\
                </div>\
                <div class="sisaltofeed-text">\
                    <div class="sisaltofeed-flag" v-if="!item.kuvat.length && item.flag_link" v-on:click="flagItem(item.nid, item.flag_link, $event)" v-bind:class="{ flagged: item.flagged == 1 }"></div>\
                    <div class="sisaltofeed-text-inner" v-html="item.body"></div>\
                </div>\
            </div>\
        </div>\
    ',
    props: ['item'],
    methods: {
        play: function(e, nid) {
            e.preventDefault();
            e.stopPropagation();
            document.getElementById('sfvideo-' + nid).play();

            jQuery(e.target).parents('.sisaltofeed-image').find('.has-video').remove();
            e.target.remove();
        },
        openModal: function(nid) {
            jQuery('.sisaltofeed-modal').remove();

            var elm_id = 'sisaltofeed-modal-' + nid;
            var elm = document.createElement('div');
            elm.id = elm_id;
            elm.className = 'modal sisaltofeed-modal';
            elm.innerHTML = '<sisaltofeed-modal v-bind:item="item"></sisaltofeed>';

            var founds = jQuery.grep(window.sisaltofeeds, function(el) { return el.nid == nid; });
            var found = founds && founds.length > 0 ? founds[0] : false;

            if(found) {
                document.body.appendChild(elm);

                new Vue({
                    el: '#' + elm_id,
                    data: {
                        item: found
                    }
                });
            }
        },
        flagItem: function(id, url, event) {
            event.stopPropagation();
            var _this = this;

            jQuery.post(url, { js: true }, function(data) {
                var mtch = data.newLink.match(/href="([^"]+)"/);
                if(mtch.length == 2) {
                    var index = window.sisaltofeeds.find(function(e) { return e.nid == id; });

                    if(index >= 0) {
                        window.sisaltofeeds[index].flag_link = mtch[1].replace('&amp;', '&');
                        window.sisaltofeeds[index].flagged = data.flagStatus == 'flagged' ? 1 : 0;
                    }
                    _this.item.flag_link = mtch[1].replace('&amp;', '&');
                    _this.item.flagged = data.flagStatus == 'flagged' ? 1 : 0;
                }
            }, 'json');
        },
    }
});

window.openModal = function(e) {
    console.log(e);
}

Vue.component('brand-item', {
    template: '\
        <div class="brand-item">\
            <div class="brand-image">\
                <img v-bind:src="item.image" v-bind:alt="item.name" v-bind:title="item.name" />\
            </div>\
            <div class="brand-name">\
                {{ item.name }}\
            </div>\
        </div>\
    ',
    props: ['item'],
});

Vue.component('sisaltofeed-modal', {
    template: '\
        <div class="modal-fade-screen" v-on:click="close($event, 0)">\
            <div class="modal-inner">\
                <h3 class="modal-title" style="display: none;"><span class="hide-text">{{ item.title }}</span></h3>\
                <div class="modal-image" v-if="!item.video && item.kuvat_original.length > 0">\
                    <img v-bind:src="item.kuvat_original[0]" alt="" />\
                </div>\
                <div class="modal-video" v-if="item.video">\
                    <video autoplay controls="controls" playsinline="playsinline">\
                        <source :src="item.video" type="video/mp4">\
                    </video>\
                </div>\
                <div class="modal-content" v-bind:class="{ \'no-modal-image\': !item.kuvat_original.length }">\
                    <div class="modal-source" v-bind:class="item.from">\
                        <div class="modal-close" v-on:click="close($event, 1)">\
                            <img src="/sites/all/themes/ratsula/img/close_red.svg" alt="X" />\
                        </div>\
                    </div>\
                    <div class="modal-content-text" v-html="item.body"></div>\
                    <div class="modal-links" v-if="item.links.length > 0">\
                        <a v-for="link in item.links" class="link-like-button" :href="link.href">{{ link.title }}</a>\
                    </div>\
                </div>\
            </div>\
        </div>',
    props: ['item'],
    mounted: function () {
        if (this.item.product && !this.item.links.find(function (item) { return item.title == 'Tuotesivulle'; })) {
            this.item.links.push({
                title: 'Tuotesivulle',
                href: this.item.product
            });
        }
    },
    methods: {
        close: function(e, close_button) {
            var remove = false;
            if(close_button) {
                remove = true;
            } else {
                if(e.target.className.indexOf('modal-fade-screen') >= 0) remove = true;
            }

            if(remove) this.$el.parentNode.removeChild(this.$el);
        }
    }
});

(function($) {
    $(document).ready(function() {
        var menu = $('nav#page-menu');
        var menuOpen = false;
        var body = $('body');

        $('#page-header .toggle-nav').click(function() {
            toggleMenu();

            return false;
        });

        $('#page-menu #page-menu-close-button').click(function () {
            toggleMenu();

            return false;
        });

        function toggleMenu() {
            menu.toggleClass('hidden');
            body.toggleClass('menu-open');
            menuOpen = !menuOpen;
        }

        $('.menu-close').click(function(e) {
            e.preventDefault();
            toggleMenu();
        });

        $('#page-menu').click(function(e) {
            if(!menuOpen) return true;

            if (!jQuery(e.target).closest(jQuery('#page-menu-inner')).length) {
                var tgn = e.target.tagName.toLowerCase();

                if(tgn != 'a' && tgn != 'img') {
                    toggleMenu();
                    return false;
                }
            }

            return true;
        });

        // $(document).on('click', '')

        var isMobile = $('.show-only-mobile').is(':visible');
        var isFront = $('body').hasClass('front');
        var siteHeaderHeight = -1;
        var mainContainerPosition = false;
        var isHidden = false;

        var isScrolled = false;
        var followMobileScroll = isMobile && isFront;
        var lastScroll = $(document).scrollTop();
        var lastScrollClass = null;

        $(document).scroll(function() {
            var scrollTop = $(this).scrollTop();

            if(scrollTop <= 0) {
                if(isScrolled) {
                    body.removeClass('scrolled');
                    isScrolled = !isScrolled;
                }
            } else {
                if(!isScrolled) {
                    body.addClass('scrolled');
                    isScrolled = !isScrolled;
                }
            }

            if(followMobileScroll) {
                if(siteHeaderHeight == -1) siteHeaderHeight = $('#site-header').height();
                if(!mainContainerPosition) mainContainerPosition = $('main').offset().top;

                if(mainContainerPosition - scrollTop + siteHeaderHeight < 0) {
                    if(!isHidden) {
                        body.addClass('scrolled-over-main');
                        isHidden = true;
                    }
                } else {
                    if(isHidden) {
                        body.removeClass('scrolled-over-main');
                        isHidden = false;
                    }
                }
            }

            if (scrollTop - lastScroll > 0) {
                if (lastScrollClass != 'scrolling-down') {
                    body.removeClass('scrolling-up');
                    body.addClass('scrolling-down');
                    lastScrollClass = 'scrolling-down';
                }
            } else if (scrollTop - lastScroll < 0) {
                if (lastScrollClass != 'scrolling-up') {
                    body.removeClass('scrolling-down');
                    body.addClass('scrolling-up');
                    lastScrollClass = 'scrolling-up';
                }
            }

            lastScroll = scrollTop;
        });

        var floorBrandList = document.getElementById('floor-brand-list-block');
        if(floorBrandList) {
            var brandMobileCss = $('<style type="text/css"></style>');
            body.append(brandMobileCss);
            window.brandFeedItemCount = 0;
            window.brandFeedPagesOnMobile = 0;
            window.brandFeedPage = 0;
            window.brandFeedItemHeight = 0;
            window.brandTouchStartPosition = 0;

            var bli = $('.kerroksen-brandit .brand-list-items');
            var nuoli_vasen = $('<div class="nuoli-vasen" style="opacity: 0;"></div>');
            var nuoli_oikea = $('<div class="nuoli-oikea"></div>');

            var bli_wrapper = $('#floor-brand-list-block');

            bli_wrapper.append(nuoli_vasen);
            bli_wrapper.append(nuoli_oikea);

                function brandFeedToLeft() {
                if(window.brandFeedPage == 0) return;

                window.brandFeedPage--;

                if(window.brandFeedPage + 2 == window.brandFeedPagesOnMobile) {
                    $('#floor-brand-list-block .nuoli-oikea').animate({ opacity: 1 }, 250);
                }

                if(window.brandFeedPage == 0) {
                    $('#floor-brand-list-block .nuoli-vasen').animate({ opacity: 0 }, 250);
                }

                $('.kerroksen-brandit .brand-list-items').css('transform', 'translateX(-' + (window.brandFeedPage * (100 / window.brandFeedPagesOnMobile)) + '%)');
            }

            $(document).on('click', '#floor-brand-list-block .nuoli-vasen', function() { brandFeedToLeft(); });
            $(document).on('click', '#floor-brand-list-block .nuoli-oikea', function() { brandFeedToRight(); });

            $(document).on('touchstart', '#floor-brand-list-block', function(e) { window.brandTouchStartPosition = e.originalEvent.touches[0].pageX; });
            $(document).on('touchend', '#floor-brand-list-block', function(e) {
                var windowWidth = $(window).width();
                var endPos = e.originalEvent.changedTouches[0].pageX;
                var diff = window.brandTouchStartPosition - endPos;

                if(Math.abs(diff / windowWidth) > 0.2) {
                    if(diff < 0) brandFeedToLeft();
                    else brandFeedToRight();
                }
            });

            function brandFeedToRight() {
                if(window.brandFeedPage + 1 == window.brandFeedPagesOnMobile) return;

                window.brandFeedPage++;

                if(window.brandFeedPage == 1) {
                    $('#floor-brand-list-block .nuoli-vasen').animate({ opacity: 1 }, 250);
                }

                if(window.brandFeedPage + 1 == window.brandFeedPagesOnMobile) {
                    $('#floor-brand-list-block .nuoli-oikea').animate({ opacity: 0 }, 250);
                }

                $('.kerroksen-brandit .brand-list-items').css('transform', 'translateX(-' + (window.brandFeedPage * (100 / window.brandFeedPagesOnMobile)) + '%)');
            }

            window.makeBrandFeedForMobileReady = function(brand_count) {
                $('#floor-brand-list-block .nuoli-vasen').fadeTo(250, 0);
                $('#floor-brand-list-block .nuoli-oikea').fadeTo(250, 0);

                if(typeof brand_count == 'undefined') brand_count = window.brandFeedItemCount;
                else window.brandFeedItemCount = brand_count;

                var rows = $(window).height() < 600 ? 1 : 2;
                var cols = $(window).width() > 550 ? 3 : 2;

                if(brand_count <= cols) rows = 1;

                if(brand_count % cols > 0) brand_count += cols - (brand_count % cols);
                window.brandFeedPagesOnMobile = Math.ceil(brand_count / (rows * cols));

                var width = 100;

                if(cols == 3) width = 100 - window.brandFeedPagesOnMobile * 2;
                var width = width / (window.brandFeedPagesOnMobile * cols);
                var cwidth = Math.ceil(brand_count / (rows * cols)) * 100;

                var css = '@media(max-width: 767px) {';
                css = css + '.kerroksen-brandit .brand-list-items { width: ' +  cwidth + '%; }';
                css = css + '.kerroksen-brandit .brand-list-items .brand-item { width: ' + width + '%; }';
                if(cols == 3) {
                    css = css + '.kerroksen-brandit .brand-list-items .brand-item:nth-child(3n+1) { margin-left: 1%; }';
                    css = css + '.kerroksen-brandit .brand-list-items .brand-item:nth-child(3n+0) { margin-right: 1%; }';
                }
                css = css + '}';

                $('.kerroksen-brandit .brand-list-items').css('transform', 'none');
                window.brandFeedPage = 0;
                $('#floor-brand-list-block .nuoli-vasen').fadeTo(1000, 0);
                $('#floor-brand-list-block .nuoli-oikea').fadeTo(1000, brand_count <= cols * rows ? 0 : 1);

                brandMobileCss.html(css);

                setTimeout(function() {
                    window.calculateArrowPositions();
                }, 1000);
            }

            var brandWindowResized = 0;

            $(window).resize(function(e) {
                brandWindowResized = Date.now();
                var thisBWR = brandWindowResized;

                setTimeout(function() {
                    if($('.show-only-mobile').is(':visible') && brandWindowResized == thisBWR) {
                        window.makeBrandFeedForMobileReady();
                    }
                }, 500);
            });

            window.calculateArrowPositions = function() {
                var rows = $(window).height() < 600 ? 1 : 2;

                var brandFeedItemHeight = $('.kerroksen-brandit .brand-list-items .brand-item:last').outerHeight(true);
                if(rows == 1) brandFeedItemHeight = brandFeedItemHeight / 2 + 10;
                else brandFeedItemHeight -= 15;

                $('#floor-brand-list-block .nuoli-vasen').css('bottom', brandFeedItemHeight + 'px');
                $('#floor-brand-list-block .nuoli-oikea').css('bottom', brandFeedItemHeight + 'px');
            }

            window.createBrandFeed('floor-brand-list-block');
        }

        var paivamaaraFields = $('.paivamaara-to-datepicker');

        paivamaaraFields.each(function() {
            var label = $('label', $(this));
            var input = $('input', $(this));

            input.datepicker({
                dateFormat: 'yy-mm-dd',
                onSelect: function() {
                    var pvm = $(this).val();
                    var d = new Date(pvm);

                    var str = [];
                    str.push(d.getDate());
                    str.push(d.getMonth() + 1);
                    str.push(d.getFullYear());

                    if(!label.hasClass('paivamaara-selected')) label.addClass('paivamaara-selected');
                    label.text(str.join('.'));
                }
            });

            if($(this).hasClass('min-two-days')) {
                input.datepicker('option', 'minDate', new Date(Date.now() + 86400*1000*5));
            }

            input.css({
                zIndex: -1,
                marginTop: '-6em',
                marginLeft: '-12em',
                position: 'absolute',
            });

            label.addClass('paivamaarafield-processed');
            label.click(function() {
                input.datepicker('show');
            });
        });

        $('.tupas-form div').click(function(e) {
            e.stopPropagation();
            var form = $(this).parent().submit();
        });

        $('.click-to-print').click(function(e) {
            $('.form-type-textarea, .form-type-textfield').each(function() {
                var area = $('textarea, input', $(this));
                var printarea = $('.print-only', $(this));

                if(printarea.length == 0) {
                    $('label', $(this)).addClass('show-when-print');
                    area.addClass('hide-from-print');
                    var printarea = $('<div class="print-only"></div>');
                    $(this).append(printarea);
                }

                printarea.text(area.val());
            });

            window.print();

            e.preventDefault();
            return false;
        });

        var pageBackground = $('#page-header-background');
        var pageBackgroundChildren = pageBackground.find('.page-header-background-item');

        if(pageBackgroundChildren.length > 1) {
            var bgChangeInterval = null;
            var currentPageBackground = 0;
            var pageBackgrounds = $('#page-header-backgrounds .page-header-background-item');
            var pageBackgroundCount = pageBackgroundChildren.length;

            var nuoli_vasen = $('<div class="nuoli-vasen"></div>');
            var nuoli_oikea = $('<div class="nuoli-oikea"></div>');

            var setImgIndex = function (idx) {
                pageBackgrounds.removeClass('active');
                pageBackgrounds[idx].classList.add('active');

                pageBackground.find('.page-header-background-pager-item.active').removeClass('active');
                pageBackground.find('.page-header-background-pager-item')[idx].classList.add('active');
            };

            var nuoliVasenFn = function() {
                var nextIdx = currentPageBackground > 0 ? currentPageBackground - 1 : 0;
                var current = $(pageBackgrounds[currentPageBackground]);
                var next = $(pageBackgrounds[--currentPageBackground]);

                current.animate({ opacity: 0 }, 250);
                next.animate({ opacity: 1 }, 250);

                currentPageBackground = nextIdx;
                setImgIndex(nextIdx);
            };

            nuoli_vasen.click(function () {
                nuoliVasenFn();

                if (bgChangeInterval !== null) {
                    clearInterval(bgChangeInterval);
                }
            });

            var nuoliOikeaFn = function() {
                var nextIdx = currentPageBackground < pageBackgroundCount - 1 ? currentPageBackground + 1 : 0;
                var current = $(pageBackgrounds[currentPageBackground]);
                var next = $(pageBackgrounds[nextIdx]);

                current.animate({ opacity: 0 }, 250);
                next.animate({ opacity: 1 }, 250);

                currentPageBackground = nextIdx;
                setImgIndex(nextIdx);
            };

            nuoli_oikea.click(function() {
                nuoliOikeaFn();

                if (bgChangeInterval !== null) {
                    clearInterval(bgChangeInterval);
                }
            });

            pageBackground.append(nuoli_vasen);
            pageBackground.append(nuoli_oikea);

            setImgIndex(0);

            bgChangeInterval = setInterval(function () {
                nuoliOikeaFn();
            }, 10000);
        }


        var listOfSellers = $('.kerroksen-myyjat');
        if(listOfSellers.length > 0) {
            var sellerMobileCss = $('<style type="text/css"></style>');
            body.append(sellerMobileCss);
            var sellersCount = 0;
            var sellerPages = 0;
            var sellerCurrentPage = 0;
            var sellerItemHeight = 0;
            var sellerTouchStartPosition = 0;
            var sellerItemCount = 0;

            var sli = $('.kerroksen-myyjat .view-group-rows');
            var nuoli_vasen = $('<div class="nuoli-vasen" style="opacity: 0;"></div>');
            var nuoli_oikea = $('<div class="nuoli-oikea"></div>');

            var sli_wrapper = $('.kerroksen-myyjat .view-group');

            sli_wrapper.append(nuoli_vasen);
            sli_wrapper.append(nuoli_oikea);

            $(document).on('click', '.kerroksen-myyjat .nuoli-vasen', function() { listOfSellersToLeft(); });
            $(document).on('click', '.kerroksen-myyjat .nuoli-oikea', function() { listOfSellersToRight(); });

            $(document).on('touchstart', '.kerroksen-myyjat', function(e) { sellerTouchStartPosition = e.originalEvent.touches[0].pageX; });
            $(document).on('touchend', '.kerroksen-myyjat', function(e) {
                var windowWidth = $(window).width();
                var endPos = e.originalEvent.changedTouches[0].pageX;
                var diff = sellerTouchStartPosition - endPos;

                if(Math.abs(diff / windowWidth) > 0.2) {
                    if(diff < 0) listOfSellersToLeft();
                    else listOfSellersToRight();
                }
            });

            function listOfSellersToLeft() {
                if(sellerCurrentPage == 0) return;

                sellerCurrentPage--;

                if(sellerCurrentPage + 2 == sellerPages) {
                    nuoli_oikea.animate({ opacity: 1 }, 250);
                }

                if(sellerCurrentPage == 0) {
                    nuoli_vasen.animate({ opacity: 0 }, 250);
                }

                sli.css('transform', 'translateX(-' + (sellerCurrentPage * (100 / sellerPages)) + '%)');
            }

            function listOfSellersToRight() {
                if(sellerCurrentPage + 1 == sellerPages) return;

                sellerCurrentPage++;

                if(sellerCurrentPage == 1) {
                    nuoli_vasen.animate({ opacity: 1 }, 250);
                }

                if(sellerCurrentPage + 1 == sellerPages) {
                    nuoli_oikea.animate({ opacity: 0 }, 250);
                }

                sli.css('transform', 'translateX(-' + (sellerCurrentPage * (100 / sellerPages)) + '%)');
            }

            function makeListOfSellerMobileReady(seller_count) {
                nuoli_vasen.fadeTo(250, 0);
                nuoli_oikea.fadeTo(250, 0);

                if(typeof seller_count == 'undefined') seller_count = sellerItemCount;
                else sellerItemCount = seller_count;

                var real_seller_count = seller_count;

                var rows = $(window).height() < 600 ? 1 : 2;

                var window_width = $(window).width();

                var cols = window_width > 550 ? 3 : (window_width < 350 ? 1 : 2);

                if(seller_count <= cols) rows = 1;

                var needed_to_fill_row = 0;
                if(seller_count % cols > 0) needed_to_fill_row = cols - (seller_count % cols);
                seller_count += needed_to_fill_row;
                sellerPages = Math.ceil(seller_count / (rows * cols));

                var ghost_rows = sellerPages * cols - Math.ceil(real_seller_count / 2);
                var width = 100;

                if(cols == 3) width = 100 - sellerPages * 2;
                var width = width / (sellerPages * cols);
                var cwidth = Math.ceil(seller_count / (rows * cols)) * 100;

                var css = '@media(max-width: 767px) {';
                css = css + '.kerroksen-myyjat .view-content .view-group-rows { width: ' +  cwidth + '%; }';
                css = css + '.kerroksen-myyjat .view-content .view-group-rows .views-row { width: ' + width + '%; }';
                if(cols == 3) {
                    css = css + '.kerroksen-myyjat .view-content .view-group-rows .views-row:nth-child(3n+1) { margin-left: 1%; }';
                    css = css + '.kerroksen-myyjat .view-content .view-group-rows .views-row:nth-child(3n+0) { margin-right: 1%; }';
                }

                if(rows == 2 && ghost_rows > 0) {
                    css = css + '.kerroksen-myyjat .view-content .view-group-rows .views-row:nth-child(' + Math.ceil(real_seller_count / 2) + ') { margin-right: ' + (ghost_rows * width) + '%; }';
                }

                css = css + '}';

                sli.css('transform', 'none');
                sellerCurrentPage = 0;
                nuoli_vasen.fadeTo(1000, 0);
                nuoli_oikea.fadeTo(1000, seller_count <= cols * rows ? 0 : 1);

                sellerMobileCss.html(css);

                setTimeout(function() {
                    calculateSellerArrowPositions();
                }, 1000);
            }

            var sellerWindowResized = 0;

            $(window).resize(function(e) {
                sellerWindowResized = Date.now();
                var thisSWR = sellerWindowResized;

                setTimeout(function() {

                    if($('.show-only-mobile').is(':visible') && sellerWindowResized == thisSWR) {
                        makeListOfSellerMobileReady();
                    }
                }, 500);
            });

            function calculateSellerArrowPositions() {
                var rows = $(window).height() < 600 ? 1 : 2;

                var itemHeight = $('.kerroksen-myyjat .views-row-last').outerHeight(true);
                if(rows == 1) itemHeight = itemHeight / 2 + 10;
                // else itemHeight -= 15;

                nuoli_vasen.css('bottom', itemHeight + 'px');
                nuoli_oikea.css('bottom', itemHeight + 'px');
            }

            makeListOfSellerMobileReady(sli.find('.views-row').length);
        }

        var aukioloaika = $('#aukioloaika');
        var aukioloajat = $('#aukioloajat-popup');
        var aukioloaika_open = false;

        if(aukioloajat.length > 0) {
            $('#aukioloaika .toggle-label').click(function() {
                if(aukioloaika_open) {
                    aukioloajat.slideUp(300, function() {
                        aukioloaika.toggleClass('opened');
                    });
                } else {
                    aukioloaika.toggleClass('opened');
                    aukioloajat.slideDown(300);
                }

                aukioloaika_open = !aukioloaika_open;
            });

            document.addEventListener('click', function (e) {
                if (aukioloaika_open) {
                    if (!aukioloaika.is(e.target) && aukioloaika.has(e.target).length === 0) {
                        $('#aukioloaika .toggle-label').click();
                    }
                }
            });
        }

        var asiakaspalvelu = $('#asiakaspalvelu');
        var asiakaspalvelu_popup = $('#asiakaspalvelu-popup');
        var asiakaspalvelu_open = false;

        if(asiakaspalvelu.length > 0) {
            $('#asiakaspalvelu .toggle-label').click(function() {
                if(asiakaspalvelu_open) {
                    asiakaspalvelu_popup.slideUp(300, function() {
                        asiakaspalvelu.toggleClass('opened');
                    });
                } else {
                    asiakaspalvelu.toggleClass('opened');
                    asiakaspalvelu_popup.slideDown(300);
                }

                asiakaspalvelu_open = !asiakaspalvelu_open;
            });

            document.addEventListener('click', function (e) {
                if (asiakaspalvelu_open) {
                    if (!asiakaspalvelu.is(e.target) && asiakaspalvelu.has(e.target).length === 0) {
                        $('#asiakaspalvelu .toggle-label').click();
                    }
                }
            });
        }

        var gomee = $('#gomee');
        var gomee_popup = $('#gomee-popup');
        var gomee_open = false;

        if(gomee.length > 0) {
            $('#gomee .toggle-label').click(function() {
                if(gomee_open) {
                    gomee_popup.slideUp(300, function() {
                        gomee.toggleClass('opened');
                    });
                } else {
                    gomee.toggleClass('opened');
                    gomee_popup.slideDown(300);
                }

                gomee_open = !gomee_open;
            });

            document.addEventListener('click', function (e) {
                if (gomee_open) {
                    if (!gomee.is(e.target) && gomee.has(e.target).length === 0) {
                        $('#gomee .toggle-label').click();
                    }
                }
            });
        }

        var lahjakortti = $('#edit-field-lahjakortin-tiedot-collect');

        if(lahjakortti.length > 0) {
            var checkPostitus = function(item) {
                var container = item.closest('.form-wrapper').parent();

                if(item.val() == '1') {
                    $('.field-name-field-saajan-postiosoite', container).show();
                    $('.field-name-field-saajan-postinumero2', container).show();
                    $('.field-name-field-saajan-postitoimipaikka2', container).show();
                } else {
                    $('.field-name-field-saajan-postiosoite', container).hide();
                    $('.field-name-field-saajan-postinumero2', container).hide();
                    $('.field-name-field-saajan-postitoimipaikka2', container).hide();
                }

                if(item.val() == '3') {
                    $('.field-name-field-saajan-email', container).show();
                } else {
                    $('.field-name-field-saajan-email', container).hide();
                }
            };

            var checkAllPostitus = function() {
                lahjakortti.find('.field-name-field-postitus').each(function() {
                    var t = $(this);

                    var inpt = $('input:checked', t);
                    if(inpt.length > 0) checkPostitus(inpt);
                    else checkPostitus(t.find('input').first());
                });
            };

            var checkAndSetKokonaissumma = function() {
                var summa = 0;

                lahjakortti.find('.field-name-field-postitus input[type="radio"]').each(function() {
                    if([ 1, 2 ].indexOf(parseInt($(this).val())) >= 0 && $(this).is(':checked')) summa += 2;
                });

                lahjakortti.find('.field-name-field-lahjakortin-summa input').each(function() {
                    var arvo = parseFloat($(this).val().replace(',', '.'));

                    if(arvo) {
                        summa += arvo;
                    }
                });

                $('#tilauksen-kokonaissumma-summa').text(summa + ' €');
            };

            checkAllPostitus();
            checkAndSetKokonaissumma();

            lahjakortti.on('change', '.field-name-field-postitus input[type="radio"]', function() { checkPostitus($(this)); checkAndSetKokonaissumma(); });
            lahjakortti.on('keyup', '.field-name-field-lahjakortin-summa input', function() { checkAndSetKokonaissumma(); });

            $(document).ajaxComplete(function(e) {
                for(var i = 1; i < 6; i++) {
                    setTimeout(function() { checkAllPostitus(); }, i * 250);
                }

                checkAndSetKokonaissumma();
            });
        }


    });

})(jQuery);

window.sisaltofeeds = [];

window.createSisaltoFeed = function(elmId, hashtags, url) {
    var filterTids = [];
    var filters = [];
    var hasAll = false;
    for(var i in hashtags) {
        if(i == '*') {
            hasAll = true;
            filters.push({ id: null, name: hashtags[i] });
        } else {
            filterTids.push(encodeURIComponent('tags[]') + '=' + i);
            filters.push({ id: i, name: hashtags[i] });
        }
    }

    if(!hasAll) filters.unshift({ id: null, name: 'Kaikki' });

    window.feedItems.push(elmId);

    var actvFilter = filters[0].id;

    new Vue({
        el: '#' + elmId,
        data: {
            items: [],
            emptyText: '',
            filters: filters,
            page: 0,
            activeFilter: actvFilter,
            hasPossibleMore: false,
            loading: false,
            loadedItems: 0,
        },
        mounted: function() {
            this.getData(true);
            var _this = this;

            jQuery(window).resize(function() {
                if(_this.items.length > 0) window.rearrangeSisaltoFeeds(_this.items);
            });
        },
        methods: {
            getData: function(clear) {
                var itemCount = Drupal.settings.sisaltofeed.items_per_page_desktop;
                if(window.getWindowWidth() < 768) itemCount = Drupal.settings.sisaltofeed.items_per_page_mobile;

                this.loading = true;
                var _this = this;

                if(this.activeFilter) {
                    var filterWith = encodeURIComponent('tags[]') + '=' + this.activeFilter;
                } else {
                    var filterWith = Drupal.settings.sisaltofeed.all ? 'fall=1' : filterTids.join('&');
                }

                jQuery.getJSON(Drupal.settings.basePath + url + '?limit=' + itemCount + '&start=' + this.loadedItems + '&' + filterWith, function(data) {
                    var items = data.items;
                    _this.loadedItems = _this.loadedItems + parseInt(itemCount);
                    _this.emptyText = data.empty;

                    if(clear) _this.items = [];

                    for(var i in items) {
                        var mtch = items[i].flag ? items[i].flag.match(/href="([^"]+)"/) : false;
                        if(mtch && mtch.length == 2) {
                            items[i].flag_link = mtch[1].replace('&amp;', '&');
                        } else {
                            items[i].flag_link = null;
                        }

                        window.sisaltofeeds.push(items[i]);
                        _this.items.push(items[i]);
                    }

                    _this.loading = false;

                    window.rearrangeSisaltoFeeds(_this.items);

                    window.setTimeout(function() {
                        // calculateSisaltoFeedItemsWidth(elmId);
                    }, 10);

                    _this.hasPossibleMore = itemCount == items.length;
                }, function(err) {
                    console.log('Error on getting sisaltofeed: ', err);
                });
            },
            filterHashtag: function(tid, e) {
                if(tid == this.activeFilter) this.activeFilter = null;
                else this.activeFilter = tid;

                this.loadedItems = 0;
                this.getData(true);
            }
        }
    });
}

window.currentWindowSize = null; // large, <= 860 medium, <= 640 mobile
window.setCurrentWindowSize = function() {
    var width = window.getWindowWidth();

    if(width <= 640) window.currentWindowSize = 'mobile';
    else if(width <= 860) window.currentWindowSize = 'medium';
    else window.currentWindowSize = 'large';
}
window.getWindowWidth = function() {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
};

window.rearrangeSisaltoFeeds = function(items) {
    var window_width = getWindowWidth();
    var oldWindowSize = window.currentWindowSize;
    setCurrentWindowSize();

    if(oldWindowSize == currentWindowSize) return;

    var cols = 3;

    var newOrder = [];
    var curCol = 0;

    var itemQueue = [];
    items.splice(0, items.length);

    if(window.currentWindowSize == 'mobile') {
        for(var i in window.sisaltofeeds) items.push(window.sisaltofeeds[i]);
        return;
    } else if(window.currentWindowSize == 'medium') {
        cols = 2;
    }

    var itemsToItearate = [];

    for(var i in window.sisaltofeeds) itemsToItearate.push(window.sisaltofeeds[i]);

    while(itemsToItearate.length > 0) {
        var item = null;

        if(itemQueue.length > 0) {
            if(cols - curCol > 1) item = itemQueue.shift();
        }

        if(!item) item = itemsToItearate.shift();
        if(!item) continue;

        if(item.leveys == '2') {
            if(cols - curCol <= 1) {
                itemQueue.push(item);
                continue;
            } else {
                newOrder.push(item);
            }
        } else {
            newOrder.push(item);
        }

        curCol++;

        if(cols == 2 && curCol == 2) curCol = 0;
        if(cols == 3 && curCol == 3) curCol = 0;
    }

    for(var i in itemQueue) newOrder.push(itemQueue[i]);
    for(var i in newOrder) items.push(newOrder[i]);
}

window.brandItems = [];

window.createBrandFeed = function(elmId) {
    new Vue({
        el: '#' + elmId,
        data: {
            items: [],
            filters: [],
            activeFilter: null
        },
        mounted: function() {
            this.getData();
        },
        methods: {
            getData: function() {
                var _this = this;

                jQuery.getJSON(Drupal.settings.basePath + 'kerroksen-brandit/' + Drupal.settings.ratsula.nid, function(data) {
                    _this.filters = data.filters;
                    _this.filters.push({ tid: null, name: 'Kaikki' });

                    for(var i in data.items) {
                        window.brandItems.push(data.items[i]);
                    }

                    _this.filterBy(_this.filters[0].tid);
                }, function(error) {
                    console.log(error);
                });
            },
            filterBy: function(id) {
                if(id == this.activeFilter || id == null) {
                    this.activeFilter = null;
                    this.items = window.brandItems;
                    window.makeBrandFeedForMobileReady(window.brandItems.length);
                    return;
                }

                this.activeFilter = id;

                var newBrands = [];

                for(var i in window.brandItems) {
                    var itm = window.brandItems[i];

                    if(itm.product_types.indexOf(id) >= 0) {
                        newBrands.push(itm);
                    }
                }

                window.makeBrandFeedForMobileReady(newBrands.length);

                this.items = newBrands;
            }
        }
    })
}

window.createRatsulaToast = function(content, closeAfter) {
    var container = document.createElement('div');
    container.classList.add('ratsula-toast');

    var inner = document.createElement('div');
    inner.classList.add('inner');
    inner.innerHTML = content;

    container.appendChild(inner);
    document.body.appendChild(container);

    setTimeout(function() {
        container.classList.add('show');

        setTimeout(function() {
            container.classList.remove('show');

            setTimeout(function() {
                container.remove();
            }, 1000);
        }, closeAfter || 3000);
    }, 20);
};

window.whenRatsulaToastReady = window.whenRatsulaToastReady || [];
var idx = 0;
window.whenRatsulaToastReady.forEach(function(toast) {
    setTimeout(function () {
        window.createRatsulaToast(toast);
    }, idx++ * 3000)
});
